// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [

	{
		id: 1,
		title: 'Click Cart',
		category: 'Web Application',
		img: require('@/assets/images/cc-1.png'),
		imgno1 : require('@/assets/images/cc-1.png'),
		imgno2 : require('@/assets/images/cc-2.png'),
		imgno3 : require('@/assets/images/cc-3.png'),
		date: "Nov , 2022",
		client : {
			name : "ClickCart ",
			services : "Full Stack Development",
			website  : "https://clickcart.arhamnatiq.com" , 
		},
		objective : `ClickCart is a Laravel-based e-commerce platform offering dynamic admin controls, multi-payment gateway support, real-time user-vendor chat, and responsive data management with jQuery DataTables for streamlined online shopping`,
		tools : 
		[
			'Laravel',
			'Php',
			'Jquery',
			'JavaScript',
			'HTML/CSS',
			'Bootstrap',
			'Stripe',
			'PayPal',
			'Two Checkout'
		],
		detail1:`ClickCart is a robust e-commerce application designed to streamline online shopping with three distinct roles: Admin, User, and Vendor. Built with Laravel and jQuery, it features:`,
		detail2:`- Dynamic Admin Panel: Fully customizable for  efficient management of users, products, and orders.
 		 Multi-Payment Gateway Integration: Supports Stripe, PayPal Checkout, Razorpay, and 2Checkout for seamless transactions.`,
		detail3:`- Real-time Chat System: Enables effective communication between users and vendors.
 		 Advanced Data Management: Powered by jQuery DataTables for a responsive and interactive user experience.`,

	},
	{
		id: 1,
		title: 'Hospital Management System',
		category: 'Web Application',
		img: require('@/assets/images/hms-new.png'),
		imgno1 : require('@/assets/images/hms-new.png'),
		imgno2 : require('@/assets/images/hms-b1.png'),
		imgno3 : require('@/assets/images/hms-b2.png'),
		date: "December , 2022",
		client : {
			name : "HMS LTD ",
			services : "UI design & Full Stack Development",
			website  : "" , 
			videoLink : "https://www.loom.com/share/d6c43ece39db461a94ec9a3b938eec1c"
		},
		objective : `The Hospital Management System (HMS) aims to automate and streamline hospital operations,
		 including patient registration, appointment scheduling, billing, and medical record management.
		  The system enhances efficiency, reduces manual errors, and improves overall patient care by providing 
		  seamless access to key data for healthcare professionals.`,
		tools : 
		[
			'Laravel',
			'Php',
			'JavaScript',
			'HTML/CSS',
			'Bootstrap',
			'Stripe'
		],
		detail1:`Hospital Management System, a user-centered platform developed to streamline healthcare operations and improve patient experience. Designed with PHP and JavaScript, this solution offers a seamless way for patients to book appointments and manage their healthcare journey through an intuitive interface.
		 Each user, whether a patient, doctor, or admin, has a tailored experience, ensuring that everyone can navigate their specific tasks with ease.`,
		detail2:`This system prioritizes security and efficiency. Admins have a comprehensive dashboard that gives them full control over users, doctors, 
		and appointments, allowing them to monitor activities and approve doctor registrations. Doctors have their own secure corner, where they can manage their appointments, view patient information, and prescribe medications. Patients benefit from a smooth and straightforward booking process, where they can schedule, reschedule, or cancel appointments with just a few clicks. Each role’s unique capabilities create a balanced, accessible experience for all users.`,
		detail3:`The Hospital Management System is more than just software; it’s a step toward better healthcare management.
		 By integrating technology into the healthcare system, this project aims to improve efficiency, minimize errors, and give patients 
		 a greater sense of control over their healthcare experience. I'm incredibly proud of the work my team and I have done to bring this vision to life.`,

	},
	{
		id: 2,
		title: 'Subscription Management System',
		category: 'Web Application',
		img: require('@/assets/images/sms-banner/one.png'),
		imgno1 : require('@/assets/images/sms-banner/one.png'),
		imgno2 : require('@/assets/images/sms-banner/two.png'),
		imgno3 : require('@/assets/images/sms-banner/three.png'),
		date: "September , 2024",
		client : {
			name : "SubMate Pro ",
			services : "Web Application Development",
			website  : " https://submate-pro.arhamnatiq.com"
		},
		objective : `The Subscription Management System streamlines the management of subscription services by integrating multiple payment gateways, including Stripe, PayPal, and Authorize.net. It supports seamless payment processing, automated billing, and refund handling, with the flexibility to dynamically choose the desired gateway based on user needs. This system enhances efficiency and improves user experience by offering versatile payment and management options.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Bootstrap',
			'Stripe',
			'Authorize.net',
		],
		detail1:`A Subscription Management SPA! Built using the power of Laravel 10 and Vue.js 3, this web app ensures seamless, secure user experiences with Sentinel and Tysom JWT handling robust authentication. Users can begin their journey by purchasing subscription plans, and payments are streamlined with integrations like Stripe, PayPal, and Authorize.Net, supporting easy and secure transactions. Whether it's refunds or renewals, each transaction is efficient and secure, ensuring every user's needs are met with convenience. `,
		detail2:`Managing subscriptions has never been easier with this app’s extensive CRUD functionality. Users, team members, and admins alike can manage plans, features, and access through a beautifully designed dashboard, with roles tailored for both end-users and admins. With real-time updates, a user can track active plans, view days remaining, and access transaction histories, while admin tools include control over plan customizations, feature access, and a smooth refund system powered by Stripe and PayPal integrations. The pivot table views further simplify access management, providing clear insight into permissions and features for any plan.`,
		detail3:`This SPA not only provides seamless subscription and management tools but also enhances user engagement with automated notifications and intuitive navigation. Access to the dashboard is a simple, streamlined process after plan purchase, allowing users to jump directly into managing their subscriptions. Designed to support businesses and individuals alike, this subscription management app is a modern, efficient solution, ensuring that every aspect of subscription handling—from payment to plan adjustments—is easy, secure, and user-friendly.`

	},
	{
		id: 3,
		title: 'Guide Managing & Reservation Booking System',
		category: 'Web Application',
		img: require('@/assets/images/gms-banners/one.png'),
		imgno1 : require('@/assets/images/gms-banners/one.png'),
		imgno2 : require('@/assets/images/gms-banners/two.png'),
		imgno3 : require('@/assets/images/gms-banners/three.png'),
		date: "June 2023",
		client : {
			name : "GM & Reservation LTD",
			services : "Web Application Development",
			website  : " https://gms.net"
		},
		objective : `The Guide Management and Reservation Booking System enables users to book trips and manage reservations seamlessly, with a dedicated guide assigned to assist throughout the journey. It simplifies the process of purchasing trips, securing bookings, and ensuring personalized guidance, enhancing the overall travel experience and ensuring user satisfaction.`,	
		tools : 
		[
			'Laravel',
			'Php',
			'Bootstrap',
			'Stripe',
			'Authorize.net',
			'JavaScript',
			'JQuery'
		],
		detail1:` A Guide Management and Reservation Booking System! Developed using Laravel, this system is a robust and user-centric solution designed to streamline guide reservations and enhance the booking experience. With an Admin Reservation System, admins can efficiently reserve slots for guides, ensuring smooth operations. For customers, the user-friendly frontend makes it simple to explore and book reservations, creating a seamless journey from selection to payment.`,
		detail2:`To make payments easy and secure, the system integrates with Stripe, offering a hassle-free transaction experience. An interactive calendar enriches the user interface by displaying booked reservations on specific dates, making it easier for users to select available slots and stay informed. Additionally, the customizable settings empower admins to modify the website’s name and disable bookings on specific dates, providing flexibility and control to tailor the experience as needed.`,
		detail3:`Built with Laravel and incorporating Mailtrap for efficient email testing, this Guide Management and Reservation System is a powerful, scalable solution for those looking to manage guide services effectively. The project is a step towards redefining the guide booking experience with user-friendly features, secure payments, and intuitive management tools.`,
	},
	{
		id: 4,
		title: 'AnyStream',
		category: 'Web Application',
		img: require('@/assets/images/anystream-new.png'),
		date: "Aug ,2022",
		imgno1 : require('@/assets/images/anystream-new.png'),
		imgno2 : require('@/assets/images/anystream-b2.png'),
		imgno3 : require('@/assets/images/anystream-3.jpg'),
		client : {
			name : "AnyStream",
			services : "Web Application Development",
			website  : " https://any-steam.net/home"
		},
		objective : `To create an engaging, visually dynamic platform where users can seamlessly explore and interact with multimedia content, 
		such as videos and images, related to diverse brands. This platform, built using Laravel, PHP,
		JavaScript, jQuery, and Bootstrap, will offer an intuitive and responsive user experience
		that encourages brand engagement, content sharing, and easy navigation. Anystream aims to
		become a go-to source for users to access rich media content that strengthens brand recognition
		and user interaction.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Bootstrap',
			'JQuery',
			'JavaScript',
			'Ajax',
			'API',
		],
		detail1: `AnyStream is a comprehensive multimedia engagement platform designed to offer users an immersive experience where they can seamlessly explore and interact with multimedia content, including videos and images, related to diverse brands. Built using Laravel, PHP, JavaScript, jQuery, and Bootstrap, this platform emphasizes intuitive and responsive navigation, encouraging brand engagement and content sharing. With a user-centric design, AnyStream simplifies the journey from content discovery to sharing, creating a visually engaging and interactive experience.`,
		detail2:`Streamlined features such as secure media sharing and an interactive gallery showcase branded content in a responsive format, enriching the user experience. AnyStream’s customizable settings allow administrators to manage platform aesthetics and control content visibility, enabling a fresh and updated user interface. This flexibility gives administrators the tools to provide a visually dynamic and organized multimedia experience that keeps users engaged and encourages interaction.`,
		detail3:`Powered by Laravel and enhanced with Mailtrap for reliable email testing, AnyStream is a robust, scalable solution tailored to the evolving needs of multimedia platforms. With its user-friendly features, secure sharing options, and easy management tools, AnyStream aims to redefine multimedia exploration and brand engagement. By bridging the gap between brands and audiences, AnyStream sets a new standard in interactive media, becoming a go-to source for accessing rich media content and enhancing brand recognition.`

	},
	{
		id: 5,
		title: 'Burger Latte',
		category: 'Web Application',
		img: require('@/assets/images/bgl-new.png'),
		imgno1 : require('@/assets/images/bgl-new.png'),
		imgno2 : require('@/assets/images/bgl-b2.png'),
		imgno3 : require('@/assets/images/bgl-b3.png'),
		date: "Aug , 2023",
		client : {
			name : "Burger Latte",
			services : "Web Application Development",
			website  : " https://bugerToHome.com"
		},
		objective : `Burger Latte is an online food delivery app focused on delivering a convenient and enjoyable ordering experience for users craving delicious burgers and coffee. With secure payment options powered by Stripe and PayPal, the app ensures smooth transactions and user trust. Built to handle high traffic and fast orders, Burger Latte provides a responsive, user-friendly interface where customers can quickly browse the menu, customize orders, and track delivery in real-time. The app aims to be the go-to platform for satisfying cravings with just a few clicks, offering a seamless journey from order to doorstep.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Bootstrap',
			'JavaScript',
			'API',			
		],
		detail1:`Burger Latte is an innovative online food delivery app designed for customers who crave quick access to delicious burgers and coffee. With a user-focused approach, the app provides a convenient and enjoyable ordering experience, making it easy for customers to browse the menu, customize their orders, and receive their food with just a few clicks. Whether you’re in the mood for a classic burger or a refreshing coffee, Burger Latte is crafted to satisfy cravings on demand`,
		detail2:`To ensure secure and reliable payments, Burger Latte integrates with trusted gateways like Stripe and PayPal, delivering a smooth transaction experience that enhances user confidence. Built to handle high volumes of traffic, the app provides a responsive and user-friendly interface, where customers can effortlessly place and track their orders in real-time, creating a seamless journey from browsing to doorstep delivery.`,
		detail3:`With its combination of intuitive design, secure payments, and high performance, Burger Latte aims to become the go-to platform for food lovers who want quality, speed, and convenience in their delivery experience. Burger Latte’s commitment to user satisfaction and reliable service makes it a top choice for satisfying burger and coffee cravings efficiently and enjoyably.`,

	},
	{
		id: 6,
		title: 'Quiz App',
		category: 'Web Application',
		img: require('@/assets/images/quiz-new.png'),
		imgno1: require('@/assets/images/quiz-new.png'),
		imgno2 : require('@/assets/images/quiz-b2.png'),
		imgno3 : require('@/assets/images/quiz-b3.png'),
		date: "May , 2024",
		client : {
			name : "Quiz App",
			services : "Web Application Development",
			website  : " https://quiz-queet.com"
		},
		objective : `The Quiz App is designed to create engaging, interactive quizzes that test users' knowledge across various topics. It allows users to participate in quiz shows, with questions designed to challenge and entertain. The app calculates scores based on the users' answers, providing instant feedback and displaying correct answers to enhance learning. The goal is to offer an intuitive and educational experience that tracks progress, encourages competition, and makes learning enjoyable and accessible for all users.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Inertia.js',
			'Bootstrap',
			'JavaScript',
			'API',	
			'Json'

		],
		detail1:`The Quiz App is designed to deliver an engaging and interactive quiz experience that challenges users' knowledge across a variety of topics. With carefully crafted questions, the app provides an exciting platform for users to participate in quiz shows that test their skills and knowledge. The app is focused on creating an enjoyable and educational experience where users can not only have fun but also learn from their mistakes and successes.`,
		detail2:`The app tracks users' progress by calculating scores based on their answers and offering instant feedback after each question. Correct answers are displayed, enhancing the learning process by helping users understand their mistakes and learn the right information. This feature makes the app both an entertaining game and a valuable educational tool, encouraging users to keep improving their knowledge while competing against themselves or others.`,
		detail3:`Designed to be intuitive and accessible, the Quiz App aims to make learning fun and competitive. By offering quizzes on a wide range of topics, it fosters an environment where users can challenge themselves and others. Whether for casual fun or deeper learning, the app encourages users to keep coming back, track their progress, and enjoy a rewarding learning experience that combines entertainment and education in a seamless way.`,
	},
	{
		id: 7,
		title: 'LevantaGpt',
		category: 'Web Application',
		img: require('@/assets/images/levanta-new.png'),
		imgno1: require('@/assets/images/levanta-new.png'),
		imgno2 : 'https://via.placeholder.com/150',
		imgno3 : 'https://via.placeholder.com/150',
		date: "Feb ,2024",
		client : {
			name : "Levanta Gpt",
			services : "Web Application Development",
			website  : " https://levantagpt.com"
		},
		objective : `Levanta GPT aims to deliver an intelligent, conversational AI platform powered by OpenAI APIs, enabling seamless, natural interactions for users seeking information or assistance. Built with Laravel, Inertia.js, and Vue, this project combines robust backend functionality with a responsive, dynamic frontend, creating an intuitive and efficient user experience. Levanta GPT is designed to streamline user queries and provide insightful responses, all within a visually engaging interface that fosters high user engagement and satisfaction.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Bootstrap',
			'JavaScript',
			'API',	
			'OpenAI',
			'Inertia.js',
		],
		detail1:`Levanta GPT is an advanced conversational AI platform powered by OpenAI APIs, built to offer intelligent, natural interactions for users in need of quick information or assistance. The platform combines robust backend capabilities with an interactive frontend, allowing users to engage in seamless conversations for an efficient, informative experience.`,
		detail2:`Developed using Laravel, Inertia.js, and Vue, Levanta GPT is designed with performance and responsiveness in mind. Its dynamic frontend provides users with a visually engaging interface that enhances usability, while the backend is optimized to handle complex queries. This setup ensures users can navigate and interact with the platform intuitively, fostering a high level of engagement.`,
		detail3:`Levanta GPT aims to transform how users access information, offering quick, accurate responses in a user-friendly environment. By merging the power of conversational AI with a polished, responsive design, the platform strives to meet and exceed user expectations, making it a valuable tool for those seeking immediate, insightful support.`

	},
	{
		id: 8,
		title: 'Y-school Systems',
		category: 'Web Application',
		img: require('@/assets/images/y-new.png'),
		imgno1: require('@/assets/images/y-new.png'),
		imgno2 : require('@/assets/images/ys-2.png'),
		imgno3 : require('@/assets/images/ys-3.png'),
		date: "Oct ,2024",
		client : {
			name : "Y-school Systems",
			services : "Web Application Development",
			website  : " https://homeOfSchooling.com"
		},
		objective : `Y-School Systems is a comprehensive school management platform designed to centralize and streamline the management of student records, teacher information, and administrative tasks within educational institutions. This system enables efficient tracking of student performance, attendance, and personal data, while providing tools for teachers to manage classes, assignments, and communications. For administrators, Y-School Systems offers powerful tools for scheduling, resource allocation, and overall school governance, ensuring a cohesive, organized approach to school management that enhances both educational quality and operational efficiency.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Bootstrap',
			'JavaScript',
			'API',
			'Jquery',
			'Stripe',
			'PayPal'
		],
		detail1:`Y-School Systems is an all-in-one school management platform built to simplify the complexities of managing student, teacher, and administrative tasks within educational institutions. With features for tracking student performance, attendance, and personal data, the platform provides a central hub for managing all aspects of school operations. Teachers are equipped with the tools to manage their classes, assignments, and communicate with students, creating a more efficient and organized teaching environment`,
		detail2:`The system also empowers administrators with powerful tools for scheduling, resource allocation, and overall governance. By centralizing key school functions, Y-School Systems enhances operational efficiency, making it easier to manage day-to-day tasks. With a user-friendly interface built using Laravel, Vue.js, and Bootstrap, the system ensures that all users—from students to administrators—have a seamless and intuitive experience that fosters better organization and workflow.`,
		detail3:`Y-School Systems is designed with both scalability and security in mind, supporting various payment methods like Stripe and PayPal for smooth transactions. The platform integrates with APIs to provide extended functionalities, ensuring it can adapt to the needs of different educational institutions. By offering a comprehensive set of tools and a responsive user interface, Y-School Systems helps schools focus on delivering high-quality education while simplifying administrative processes.`

	},
	{
		id: 9,
		title: 'Lemoni (E-commerce App)',
		category: 'Web Application',
		img: require('@/assets/images/lemoni-new.png'),
		imgno1: require('@/assets/images/lemoni-new.png'),
		imgno2 : require('@/assets/images/lemoni-2.png'),
		imgno3 : require('@/assets/images/lemoni-3.png'),
		date: "March ,2022",


		client : {
			name : "Lemoni LLC",
			services : "Web Application Development",
			website  : " https://lemoni.org"
		},
		objective : `Lemoni is an e-commerce app designed to deliver a fresh, user-friendly shopping experience, offering a wide range of products across various categories. The app focuses on simplifying the online shopping process with easy navigation, personalized recommendations, and secure payment options. With a streamlined interface and fast checkout, Lemoni aims to create a seamless, enjoyable platform for users to explore, select, and purchase products with confidence, fostering customer loyalty and satisfaction in the growing digital marketplace.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Bootstrap',
			'JavaScript',
			'API',		
			'Stripe',
			'Authorize.net',
			'Two Checkout'	
		],
		detail1:`Lemoni is a user-centric e-commerce app designed to provide a seamless and intuitive shopping experience for customers. By offering a wide variety of products across multiple categories, the app allows users to easily browse and find what they need. With its simple navigation and personalized recommendations, Lemoni ensures that every user can quickly discover products that match their preferences, making the shopping experience smooth and enjoyable.`,
		detail2:`The app integrates secure payment gateways such as Stripe, Authorize.net, and Two Checkout, providing users with flexible and trusted options for completing their transactions. With fast and efficient checkout processes, Lemoni eliminates the usual pain points of online shopping, allowing customers to complete their purchases with ease and confidence. This makes it a go-to platform for users who value both convenience and security in their shopping experience.`,
		detail3:`Built using Laravel and Bootstrap, Lemoni combines a powerful backend with a responsive and engaging frontend, ensuring a seamless shopping experience across devices. The app’s intuitive interface and fast performance create a pleasurable environment for users to explore, select, and purchase products. Lemoni is not just a shopping app but a platform designed to build lasting customer loyalty and satisfaction in an ever-expanding digital marketplace.`

	},
	{
		id: 10,
		title: 'Application Tracking System (Job Portal)',
		category: 'Web Application',
		img: require('@/assets/images/ats.jpg'),
		imgno1: require('@/assets/images/ats.jpg'),
		// imgno2: require('@/assets/images/ats.jpg'),
		// imgno3: require('@/assets/images/ats.jpg'),
		imgno2 : 'https://via.placeholder.com/150',
		imgno3 : 'https://via.placeholder.com/150',
		date: "July ,2024",
		client : {
			name : "Hire Cruise",
			services : "Web Application Development",
			website  : " https://hire-cruise.com"
		},
		objective : `The Application Tracking System (ATS) is a job portal designed to connect employers with qualified candidates while streamlining the recruitment process. It enables employers to post job openings, manage applications, and track candidate progress through each stage of hiring. For job seekers, the platform provides tools to search for relevant job listings, submit applications, and receive updates on their status. The ATS aims to create an efficient and organized hiring experience for both applicants and recruiters, reducing time-to-hire and improving candidate engagement through a centralized, user-friendly interface.`,
		tools : 
		[
			'Laravel',
			'Php',
			'React.js',
			'Bootstrap',
			'JavaScript',
			'API',		
			'Stripe',
			'PayPal',
			'Authorize.net'	
		],
		detail1:`The Application Tracking System (ATS) is a comprehensive job portal designed to streamline the hiring process by connecting employers with qualified candidates. Employers can effortlessly post job openings, manage applications, and monitor candidates' progress through the various stages of the recruitment process. With an intuitive, user-friendly interface, the platform ensures employers can efficiently track and evaluate applicants, making the hiring process more organized and less time-consuming.`,
		detail2:`For job seekers, ATS offers a dynamic platform where they can easily search for job listings that match their skills and experience, submit applications, and receive real-time updates on their application status. By providing a centralized hub for job seekers to manage their applications and stay informed, the system enhances user engagement and fosters a more transparent hiring experience, reducing frustration and improving candidate satisfaction.`,
		detail3:`Leveraging powerful technologies like Laravel, React.js, and Bootstrap, the ATS combines a robust backend with a modern, responsive frontend. Integrated with secure payment gateways such as Stripe, PayPal, and Authorize.net, the platform ensures secure transactions for premium services. The ATS creates a seamless, efficient recruitment experience for both job seekers and recruiters, improving the hiring workflow, reducing time-to-hire, and increasing the overall effectiveness of the recruitment process.`
	},	
	{
		id: 11,
		title: 'Trips and Memebership Management System',
		category: 'Web Application',
		img: require('@/assets/images/trip-3.png'),
		imgno1: require('@/assets/images/trip-banner.png'),
		imgno2: require('@/assets/images/trip-2.png'),
		imgno3: require('@/assets/images/trip-3.png'),
		date: "Dec ,2023",
		client : {
			name : "Trip & Membership Managing System",
			services : "Web Application Development",
			website  : "https://trip&mem-trip.com"
		},
		objective : `The Trips and Membership Management System is a platform that enables parents to purchase memberships, granting them access to exclusive travel options and discounts on trips for their families. Through membership benefits, parents can seamlessly select, book, and manage trips tailored to their needs, making travel planning convenient and affordable. The system aims to provide a streamlined, user-friendly experience that simplifies the process of exploring travel options, maximizing membership value, and fostering memorable family experiences.`,
		tools : 
		[
			'Laravel',
			'Php',
			'Vue.js',
			'Bootstrap',
			'JavaScript',
			'API',	
			'Stripe',
			'Authorize.net'		
		],
		detail1: `The Trips and Membership Management System is an innovative platform designed to simplify travel planning for families by providing access to exclusive memberships. These memberships offer a wide range of benefits, including discounted trips, priority bookings, and access to unique travel experiences. The system is tailored to help families save time and money while enjoying personalized travel options.`,
		detail2: `For parents, the platform ensures a hassle-free experience by centralizing trip planning, booking, and management in one place. With the ability to view membership benefits, explore available travel packages, and make secure payments, users can seamlessly organize their trips. Real-time notifications, booking confirmations, and user-friendly dashboards ensure a smooth and transparent process for every member.`,
		detail3: `Built with cutting-edge technologies such as Laravel, Vue.js, and Bootstrap, the system delivers a robust backend paired with a responsive, modern frontend. Secure payment integrations with Stripe and Authorize.net guarantee safe and smooth transactions. This platform is a complete solution for travel planning, enabling parents to focus on creating memorable experiences while the system handles the logistics.`,
	

	},
];

export default projects;
